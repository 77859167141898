



























































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/website/components/Header.vue";
import Footer from "@/website/components/footer.vue";
import { RegViewModel } from "../../models/RegViewModel";
import AuthService from "../../services/AuthService";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  components: {},
})
export default class Registration extends Vue {
  public submitted = false;
  public user: RegViewModel = {} as RegViewModel;

  created() {
    const dict = {
      custom: {
        firstName: {
          required: "Your full name is required.",
        },
        phone: {
          required: "The phone is required.",
          numeric: "The phone number can only contain numeric characters.",
          min: "The phone number must be at least 11 characters.",
          max: "Phone number cannot be more than 11 characters.",
        },
        password: {
          required: "A password is required.",
          min: "The password must be at least 6 characters.",
        },
      },
    };

    this.$validator.localize("en", dict);
  }

  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.registration(this.user)
          .then((response) => {
            let result = response.data as any;
            if (result.status) {
              //console.log(result.result.securityVerification.code);
              this.$store.dispatch("addVerify", {
                userId: result.result.user.id,
                verifycode: result.result.securityVerification.code,
              });
              this.$router.push({ path: "registration-verify" });
            } else {
              this.submitted = false;
              if (result.messageType == "unique") {
                this.$validator.errors.add({
                  field: "phone",
                  msg: result.message,
                });
              } else {
                AlertService.sendMessage(result.message);
              }
            }
          })
          .catch((error) => {
            this.submitted = false;
          });
      }
    });
  }
}
